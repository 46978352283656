.z5UFUG_container {
  position: relative;
}

.z5UFUG_triggerContainer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.z5UFUG_spacer {
  flex-grow: 1;
}

.z5UFUG_morphingLayer {
  background-color: #fff;
  border: 1px solid #0000000d;
  border-radius: 16px;
  gap: 4px;
  margin-top: .5rem;
  position: absolute;
  overflow: hidden scroll;
  box-shadow: 0 8px 24px #00000014;
}

.z5UFUG_morphingLayer.z5UFUG_morph {
  transition: transform .2s ease-out, opacity .3s, width .25s ease-out, height .25s ease-in-out;
}

.z5UFUG_morphingLayer.z5UFUG_hidden {
  opacity: 0;
}

.z5UFUG_morphingLayer.z5UFUG_visible {
  opacity: 1;
}

.z5UFUG_contentWrapper {
  position: relative;
}

.z5UFUG_groupContainer {
  width: 100%;
  position: absolute;
}

.z5UFUG_enter {
  opacity: 0;
  width: 100%;
}

.z5UFUG_enterActive {
  opacity: 1;
  transition: opacity .3s ease-out;
}

.z5UFUG_isMorphing.z5UFUG_enterActive {
  transition-delay: .1s;
}

.z5UFUG_exit {
  opacity: 1;
  width: 100%;
}

.z5UFUG_exitActive {
  opacity: 0;
  transition: opacity .25s ease-out;
}

.DTAwcG_Trigger {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #0000000d;
  border: none;
  border-radius: 48px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 0 12px 0 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  transition: background-color .3s;
  display: flex;
}

.DTAwcG_Trigger .DTAwcG_icon {
  opacity: .6;
  transition: opacity .2s ease-out;
  display: flex;
}

.DTAwcG_Trigger:hover, .DTAwcG_Trigger:focus {
  background: #0000001f;
}

.DTAwcG_Trigger:hover .DTAwcG_icon, .DTAwcG_Trigger:focus .DTAwcG_icon {
  opacity: 1;
}

.DTAwcG_Trigger .DTAwcG_caret {
  opacity: .6;
  transition: transform .2s ease-out;
}

.DTAwcG_isOpen .DTAwcG_caret {
  transform: rotate(180deg);
}

.DTAwcG_isOpen .DTAwcG_icon {
  opacity: 1;
}

.B2wDDG_app {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
}

.B2wDDG_wrapper {
  justify-content: center;
  display: flex;
}

.B2wDDG_container {
  flex-direction: column;
  gap: 1rem;
  width: 512px;
  display: flex;
}

.B2wDDG_search {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.B2wDDG_search .B2wDDG_results {
  opacity: .4;
  margin-left: 8px;
  font-weight: 400;
}

.zR6zMq_ItemsList {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.zR6zMq_wrapper {
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  display: flex;
}

.zR6zMq_item {
  border-radius: 48px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 13px;
  font-size: 14px;
  font-weight: 400;
  transition: background .2s ease-out;
  display: flex;
}

.zR6zMq_item:hover {
  background: #0000000d;
}

.zR6zMq_icon {
  width: 16px;
  height: 16px;
}

.zR6zMq_number {
  opacity: .4;
}

.GAedEa_PeopleFilter {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  padding: 6px 12px 12px;
  position: relative;
}

.GAedEa_list {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.GAedEa_item {
  border-radius: 48px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 14px;
  transition: background .2s ease-out;
  display: flex;
}

.GAedEa_item:hover {
  background: #0000000d;
}

.GAedEa_avatar {
  border-radius: 4px;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  font-size: 14px;
  overflow: hidden;
}

.GAedEa_name {
  flex-shrink: 0;
  font-weight: 500;
}

.GAedEa_email {
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .4;
  overflow: hidden;
}

.GAedEa_inputWrapper {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 36px;
  display: flex;
  position: relative;
}

.GAedEa_inputWrapper input {
  border: 0;
  width: 100%;
  height: 24px;
  padding: 8px 12px 8px 42px;
  font-size: 14px;
}

.GAedEa_inputWrapper .GAedEa_startAdornment {
  padding: 3px 16px 0;
  position: absolute;
}

.GAedEa_divider {
  opacity: .05;
  border-bottom: 1px solid #000;
  margin: 4px 0;
}

.AYHODG_DateFilter {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.AYHODG_wrapper {
  flex-direction: column;
  gap: 3px;
  padding: 12px;
  display: flex;
}

.AYHODG_item {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 48px;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  transition: background .2s ease-out;
  display: flex;
}

.AYHODG_item:hover {
  background: #0000000d;
}

.AYHODG_divider {
  opacity: .05;
  border-bottom: 1px solid #000;
}

html, body, #app {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: SanaSans;
  line-height: 1.5;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

input, button, textarea, select {
  font: inherit;
}

@font-face {
  font-family: SanaSans;
  font-weight: 400;
  font-style: normal;
  src: url("SanaSans-Regular.990facd1.woff2") format("woff2");
}

@font-face {
  font-family: SanaSans;
  font-weight: 500;
  font-style: normal;
  src: url("SanaSans-Medium.4fb9729b.woff2") format("woff2");
}
/*# sourceMappingURL=index.e90ef187.css.map */
