@font-face {
  font-family: "SanaSans";
  font-weight: 400;
  font-style: normal;
  src: url("../../public/assets/fonts/sana-sans/SanaSans-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "SanaSans";
  font-weight: 500;
  font-style: normal;
  src: url("../../public/assets/fonts/sana-sans/SanaSans-Medium.woff2")
    format("woff2");
}
