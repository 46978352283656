@use "../styles/mixins.scss";

html,
body,
#app {
  height: 100%;
}

body {
  font-family: SanaSans;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

// @include custom-scrollbar();
